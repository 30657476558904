import {createRouter, createWebHistory} from "vue-router";
import MainLayout from "@/layouts/Main";
import MainRoutes from "@/router/main";


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: MainLayout,
      children: MainRoutes,
      redirect: '/create/invoice',
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: 'CreateInvoice',
    }
  ]
})

export default router;