import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App);

/**
 * Vue Router
 */
import router from "@/router";
app.use(router);

/**
 * Ant Design Vue
 */
import { default as AntD, ConfigProvider } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.variable.min.css';
import AntDTheme from '@/antDTheme';
ConfigProvider.config({
  theme: AntDTheme,
});
app.use(AntD);


app.mount('#app')
