<template>
  <a-card class="create-invoice mx-auto my-3" title="Create Invoice">
    <a-row>
      <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="text-center mb-2">
        <label class="d-block mb-1">Send Payment To</label>
        <a-radio-group v-model:value="createInvoiceForm.payee" buttonStyle="solid">
          <a-radio-button value="sunbelt">Sunbelt Finance</a-radio-button>
          <a-radio-button value="default">Mand Trans Inc</a-radio-button>
        </a-radio-group>
      </a-col>

      <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="text-center">
        <label class="d-block mb-1">Invoice #</label>
        <a-input-number class="w-100" v-model:value="createInvoiceForm.invoice_num" :min="0" :max="9999999" autofocus />
      </a-col>

      <a-divider orientation="left">Broker Info</a-divider>
      <a-col :span="24">
        <input class="form-control mb-2" type="text" v-model="createInvoiceForm.broker.name" placeholder="JB Hunt" required  />
      </a-col>

      <a-col :span="24">
        <div class="input-group mb-2">
          <span class="input-group-text">Attn:</span>
          <input class="form-control" type="text" v-model="createInvoiceForm.broker.attn" placeholder="ap@broker.com"  />
        </div>
      </a-col>

      <a-col :span="24">
        <input class="form-control mb-2" type="text" v-model="createInvoiceForm.broker.address1" placeholder="123 Fake St"  />
      </a-col>

      <a-col :span="24">
        <input class="form-control mb-2" type="text" v-model="createInvoiceForm.broker.address2" placeholder="PO Box, Indianapolis, IN 46204" />
      </a-col>

      <a-divider orientation="left">Load Details</a-divider>

      <a-col :span="24">
        <div class="input-group mb-2">
          <span class="input-group-text">Load #</span>
          <input class="form-control" type="text" v-model="createInvoiceForm.load.reference" required />
        </div>
      </a-col>

      <a-col :span="24">
        <div class="input-group mb-2">
          <span class="input-group-text">Pickup From</span>
          <input class="form-control" type="text" v-model="createInvoiceForm.load.from" placeholder="Indianapolis, IN" required />
        </div>
      </a-col>

      <a-col :span="24">
        <div class="input-group mb-2">
          <span class="input-group-text">Delivered To</span>
          <input class="form-control" type="text" v-model="createInvoiceForm.load.to" placeholder="Cincinnati, OH" required />
        </div>
      </a-col>

      <a-col :span="24">
        <div class="input-group mb-2">
          <span class="input-group-text">Delivered On</span>
          <a-date-picker class="flex-grow-1" v-model:value="createInvoiceForm.load.delivered_on" format="MM/DD/YYYY" required/>
        </div>
      </a-col>

      <a-divider orientation="left">Rate</a-divider>
      <a-col :span="24">
        <div class="input-group mb-2">
          <span class="input-group-text">Flat</span>
          <a-input-number class="form-control" v-model:value="createInvoiceForm.rate.flat" :min="0.00" :step="0.01" required />
        </div>
      </a-col>

      <a-col :span="24">
        <div class="input-group mb-2">
          <span class="input-group-text">Lumper</span>
          <a-input-number  class="form-control" v-model:value="createInvoiceForm.rate.lumper" :min="0.00" :step="0.01" />
        </div>
      </a-col>

      <a-col :span="24">
        <div class="input-group mb-2">
          <span class="input-group-text">Detention</span>
          <a-input-number  class="form-control" v-model:value="createInvoiceForm.rate.detention" :min="0.00" :step="0.01" />
        </div>
      </a-col>

      <a-col :span="24">
        <a-textarea class="mb-2" :rows="4" placeholder="Notes" v-model:value="createInvoiceForm.notes" :maxlength="6" />
      </a-col>
    </a-row>

    <a-alert v-if="formIsInvalidMessage.trim()" class="mb-2" showIcon type="error" :message="formIsInvalidMessage" />
    <a-button :disabled="!!formIsInvalidMessage" type="primary" size="large" block @click="submitForm">Create Invoice</a-button>

  </a-card>
</template>

<script>
import api from "@/services/invoiceapi";

export default {
  name: "CreateInvoice",
  data() {
    return {
      createInvoiceForm: {
        invoice_num: "",
        payee: "sunbelt",
        broker: {
          name: "",
          attn: "",
          address1: "",
          address2: "",
        },
        load: {
          delivered_on: null,
          from: "",
          to: "",
          reference: "",
        },
        rate: {
          flat: 0.00,
          lumper: 0.00,
          detention: 0.00,
        },
        notes: "",
      },
    }
  },
  methods: {
    submitForm() {
      if(this.formIsInvalidMessage) return;
      let invoiceData = {
        payee: this.createInvoiceForm.payee,
        invoice_num: this.createInvoiceForm.invoice_num,
        broker_name: this.createInvoiceForm.broker.name,
        broker_attn: this.createInvoiceForm.broker.attn,
        broker_address_line1: this.createInvoiceForm.broker.address1,
        broker_address_line2: this.createInvoiceForm.broker.address2,
        load_reference: this.createInvoiceForm.load.reference,
        delivered_on: this.createInvoiceForm.load.delivered_on.format("MM/DD/YYYY"),
        load_from: this.createInvoiceForm.load.from,
        load_to: this.createInvoiceForm.load.to,
        rate_flat: this.createInvoiceForm.rate.flat,
        rate_lumper: this.createInvoiceForm.rate.lumper,
        rate_detention: this.createInvoiceForm.rate.detention,
        notes: this.createInvoiceForm.notes,
      };

      api.createInvoice(invoiceData);
    }
  },
  computed: {
    brokerValidationMessage() {
      if(!this.createInvoiceForm.broker.name || !this.createInvoiceForm.broker.name.trim()) return "Missing broker name";
      let attnMissing = !this.createInvoiceForm.broker.attn || !this.createInvoiceForm.broker.attn.trim();
      let line1Missing = !this.createInvoiceForm.broker.address1 || !this.createInvoiceForm.broker.address1.trim();
      let line2Missing = !this.createInvoiceForm.broker.address2 || !this.createInvoiceForm.broker.address2.trim();
      if(attnMissing && line1Missing && line2Missing) return "Missing broker address";
      return "";
    },
    formIsInvalidMessage() {
      if(!this.createInvoiceForm.invoice_num) return "Missing invoice #";
      if(this.brokerValidationMessage) return this.brokerValidationMessage;
      if(!this.createInvoiceForm.load.reference || !this.createInvoiceForm.load.reference.trim()) return "Missing load #";
      if(!this.createInvoiceForm.load.from || !this.createInvoiceForm.load.from.trim()) return "Missing load pickup location";
      if(!this.createInvoiceForm.load.to || !this.createInvoiceForm.load.to.trim()) return "Missing load delivery location";
      if(!this.createInvoiceForm.load.delivered_on) return "Missing load delivery date";
      return "";
    }
  }
}
</script>

<style>
.create-invoice {
  max-width: 600px;
}
</style>