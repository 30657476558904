import Api from '@/services/api';

// Function that downloads PDF
function download(data, fileName) {
  const url = window.URL.createObjectURL(data);
  const a = document.createElement('a');
  a.download = fileName;
  a.href = url;
  a.target = '_blank';
  a.click();

  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    a.remove();
    window.URL.revokeObjectURL(url);
  }, 100);
}

export default {
  createInvoice(invoiceData) {
    return Api()
      .post('/createInvoicePdf', invoiceData, {responseType: 'blob'})
      .then(resp => {
        download(new Blob([resp.data], {type: 'application/pdf'}), `${invoiceData.load_reference}_Invoice.pdf`);
        return resp;
      })
      .catch(err => err);
  }
}


