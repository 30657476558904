<template>
  <a-layout>
    <a-layout-content style="min-height: 100vh">
      <router-view />
    </a-layout-content>
  </a-layout>
</template>

<script>
// import Navbar from "@/layouts/Main/NavigationMenu";

export default {
  name: "MainLayout",
  components: {},
  data() {
    return {
      logo: require("@/assets/logo.png"),
      favicon: require("@/../public/android-chrome-192x192.png")
    }
  },
}
</script>

<style>
.logo {
  display: block;
  width: 100%;
  max-width: 192px;
  margin: auto;
  padding: 2rem;
}
</style>