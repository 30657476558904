import axios from "axios";
import {notification} from 'ant-design-vue';
import { baseUrl } from '@/services/apiConsts';
import router from '@/router';

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "application/json,application/pdf",
    "Content-Type": "application/json",
  },
});

// request interceptor
axiosInstance.interceptors.request.use(
  function(config) {
    // request data
    return config;
  },
  function(error) {
    // request error
    notification["error"]({
      message: "API Error",
      description: "Error sending api request",
    });
    return Promise.reject(error);
  },
);

// response interceptor
axiosInstance.interceptors.response.use(
  function(response) {
    // response data
    return response;
  },
  function(error) {
    if (axios.isCancel(error)) {
      return error;
    }
    const {status, data} = error.response;
    if (status === 401) {
      // if any API call returns a 401, redirect back to login
      if (router.currentRoute.matched.some(route => route.meta.authRequired) === true) {
        router.push({name: "login"});
      }
    } else {
      let message = data && data.message;

      // response error
      notification["error"]({
        message: "API Error",
        description: message && message !== "" ? message : "Invalid response from server",
      });
    }
    return Promise.reject(error);
  },
);

const Api = () => axiosInstance;

export default Api;